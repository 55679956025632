import * as React from 'react';

import Layout from '../../components/layout';

export default () => (
  <Layout seoTitle="Get a Demo">
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full mx-auto text-lg max-w-prose">
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mb-6 text-lg max-w-prose">
                <p className="text-base font-semibold leading-6 tracking-wide text-center text-teal-600 uppercase">
                  Explore the possibilities
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  Try Odoo for yourself
                </h1>
                <p className="text-xl leading-8 text-gray-500">
                  We truly believe Odoo sells itself. That&apos;s why when
                  you&apos;re kind enough to let us make our pitch we&apos;ll
                  spend very little time up front on slides and statistics and
                  bullet points &mdash; none at all if you prefer &mdash; so we
                  can get to the demo as quickly as possible.
                </p>
                <p className="py-6 text-xl leading-8 text-gray-500">
                  Here are four ways you can get a hands-on evaluation of Odoo.
                </p>
              </div>
              <div className="mx-auto prose prose-lg text-gray-500">
                <h2>Have a look on your own</h2>
                <p>
                  If you&apos;re comfortable exploring new software on your own
                  and want to take a look before deciding to talk with us, feel
                  free! Visit this page to create a trial Odoo database with the
                  apps you&apos; re interested in &mdash; instant access, no
                  credit card required.
                </p>
                <h2>Schedule a short guided demo</h2>
                <p>
                  If you&apos;d like to see Odoo in action and want to have an
                  expert show you around, schedule a demo with us. Just email{' '}
                  <a href="mailto:chris@monterey.dev">chris@monterey.dev</a>.
                </p>
                <h2>Get a custom demo</h2>
                <p>
                  If you&apos;re curious enough to invest a bit of your own
                  time, we can do a brief interview to learn about your specific
                  needs, then craft a personalized demo that will show you
                  exacty what Odoo can do for you. Email{' '}
                  <a href="mailto:chris@monterey.dev">chris@monterey.dev</a> for
                  details.
                </p>
                <h2>Your first app&mdash;no obligation</h2>
                <p>
                  Think of it as the ultimate demo&mdash;we&apos;ll prepare a
                  full installation of one app using your data. You only pay if
                  you then decide to go live. Contact{' '}
                  <a href="mailto:chris@monterey.dev">chris@monterey.dev</a> to
                  explore this option. (Limited time offer.)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
